import React from 'react'
import recruitment from './../assets/recruitment.png';
import Container from "./Container";
const ServiceIntro = () => {
  return (
    <div id="services-section" className="px-4 py-12 flex items-center justify-center bg-gradient-to-br from-slate-100 to-gray-100 h-full">
        <Container>
            <div className="container px-0 sm:px-8 xs:py-8 lg:grid lg:grid-cols-2">
                <div className="mb-16 xs:py-2 lg:mb-0 flex lg:flex lg:h-full items-center justify-center">
                    <img src={ recruitment } alt="services"/>
                </div>
                <div className="lg:mt-24 xl:mt-22 xl:ml-12 flex-col lg:h-full items-center justify-center">
                    <h1 className="text-center text-3xl font-extrabold text-blue-900 dark:text-white sm:text-4xl lg:text-left md:text-5xl  xl:text-6xl leading-8">
                            Intellect Organization<br/> 
                        <span className="bg-gradient-to-r from-blue-500 to-teal-600 bg-clip-text text-transparent">Recruitment & Staffing</span>
                        
                    </h1>
                    <p className="max-w-2xl mt-12 mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">Our team of recruiters has extensive experience across various industries, and we are constantly staying up-to-date with the latest trends and best practices in staffing and recruitment. We use cutting-edge technology and innovative approaches to identify and attract the best candidates for our clients.</p>
                    
                </div>
            </div> 
        </Container>
    </div>
  )
}

export default ServiceIntro
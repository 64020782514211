import React, { useEffect } from 'react'
import Container from "./Container";
import CurlRight from './../assets/shape-curl-right.svg'; 
import { Link } from 'react-router-dom';
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { HiMail } from "react-icons/hi";
import { BsTelephone } from "react-icons/bs";
import { FaFax, FaMobile } from "react-icons/fa";
import logo from './../assets/logo.png';
const Footer = () => {
    const copyWriteDivVariants = {
        visible: { opacity: 1, y: 0, transition: { delay:0.3, duration:1, stiffness:120, type:"spring" } },
        hidden: { opacity: 0, y: -50 }
      };
      const leftImageVariant = {
        visible: { opacity: 1, x: -50, transition: { delay:0.3, duration:1, stiffness:120, type:"spring"  } },
        hidden: { opacity: 0, x: 0 }
      };
      const controls = useAnimation();
      const [refRootDiv, inView] = useInView({ threshold: 0.2});
      useEffect(() => {
        if (inView) {
          controls.start("visible");
        }
      }, [controls, inView]);
  return (
    
           <footer className="py-6 bg-gradient-to-br from-gray-900 to-gray-600 text-gray-200  relative pt-16"  ref={ refRootDiv }>
                
                <div className="z-[5] relative">
                    <Container>
                        <motion.div
                            animate={controls}
                            initial="hidden"
                            variants={copyWriteDivVariants}
                            className="container mx-auto space-y-6 divide-y divide-gray-400 divide-opacity-50 my-12">
                            
                            <div className="grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
                                <div className="pb-6 w-full md:pb-0 sm:col-span-2">
                                    <a rel="noopener noreferrer" href="/" className="flex items-center justify-center space-x-3 md:justify-start">
                                        <div className="flex items-center justify-center w-16 h-16 rounded-full dark:bg-violet-400">
                                            <img src={logo} alt="koga step logo" className="w-16 h-16"/>
                                        </div>
                                        <span className="self-center text-2xl font-semibold bg-gradient-to-r from-blue-500 to-teal-600 bg-clip-text text-transparent">Step Support Co., Ltd</span>
                                    </a>
                                </div>
                                <div className="text-center md:text-left">
                                    <p className="pb-4 text-lg font-medium  bg-gradient-to-r from-blue-500 to-teal-600 bg-clip-text text-transparent">Usefull Links</p>
                                    <ul className="flex flex-col gap-4">
                                        <li>
                                            <a  href="/" className="hover:dark:text-violet-400 cursor-pointer">Home</a>
                                        </li>
                                        <li>
                                            <a  href="/aboutus" className="hover:dark:text-violet-400 cursor-pointe">About</a>
                                        </li>
                                        <li>
                                            <a  href="/services" className="hover:dark:text-violet-400 cursor-pointe">Services</a>
                                        </li>
                                        <li>
                                            <a  href="/contactus" className="hover:dark:text-violet-400 cursor-pointe">Contact Us</a>
                                        </li>
                                        
                                    </ul>
                                </div>
                                <div className="text-center md:text-left">
                                    <p className="pb-4 text-lg font-medium  bg-gradient-to-r from-blue-500 to-teal-600 bg-clip-text text-transparent">Get in touch with us</p>
                                    <ul className="flex flex-col gap-4 items-center md:items-start">
                                        <li>
                                            <a rel="noopener noreferrer" title="Phone" href="tel:0284228166" className="flex space-x-2 items-center"><BsTelephone/><span>0284 22 8166</span></a>
                                        </li>
                                        <li>
                                            <a rel="noopener noreferrer" title="Mobile" href="tel:08067784777" className="flex space-x-2 items-center"><FaMobile/><span>080 6778 4777</span></a>
                                        </li>
                                        <li>
                                            <a rel="noopener noreferrer" title="Fax" href="fax:0284228756" className="flex space-x-2 items-center"><FaFax/><span>0284 22 8756</span></a>
                                        </li>
                                        <li>
                                            <a rel="noopener noreferrer" title="Email" href="mailto:step.s-m@outlook.jp" className="flex space-x-2 items-center"><HiMail className="mt-1"/><span>step.s-m@outlook.jp</span></a>
                                        </li>
                                    </ul>
                                </div>
                                
                            </div>
                            <div className="grid justify-center pt-6 lg:justify-between">
                                <div className="flex flex-col self-center text-sm text-center md:block lg:col-start-1 md:space-x-6">
                                    <span>©2023 All rights reserved</span>
                                    {/*
                                    <a rel="noopener noreferrer" href="#">
                                        <span>Privacy policy</span>
                                    </a>
                                    <a rel="noopener noreferrer" href="#">
                                        <span>Terms of service</span>
                                    </a>
                                    */}
                                </div>
                                <div className="flex justify-center pt-4 space-x-4 lg:pt-0 lg:col-end-13">
                                    <a rel="noopener noreferrer" href="#" title="Facebook" className="flex items-center justify-center w-10 h-10 rounded-full dark:bg-violet-400 dark:text-gray-900">
                                    <svg className="text-gray-300" width="24" height="24" viewBox="0 0 24 24" fill="#d1d8e0" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_48_1499)">
                                            <path d="M14 13.5H16.5L17.5 9.5H14V7.5C14 6.47 14 5.5 16 5.5H17.5V2.14C17.174 2.097 15.943 2 14.643 2C11.928 2 10 3.657 10 6.7V9.5H7V13.5H10V22H14V13.5Z" fill=""></path>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_48_1499">
                                                <rect width="24" height="24" fill="white"></rect>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    </a>
                                    <a rel="noopener noreferrer" href="#" title="Twitter" className="flex items-center justify-center w-10 h-10 rounded-full dark:bg-violet-400 dark:text-gray-900">
                                    <svg className="text-gray-300" width="24" height="24" viewBox="0 0 24 24" fill="#d1d8e0" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_48_1502)">
                                        <path d="M22.162 5.65593C21.3985 5.99362 20.589 6.2154 19.76 6.31393C20.6337 5.79136 21.2877 4.96894 21.6 3.99993C20.78 4.48793 19.881 4.82993 18.944 5.01493C18.3146 4.34151 17.4803 3.89489 16.5709 3.74451C15.6615 3.59413 14.7279 3.74842 13.9153 4.18338C13.1026 4.61834 12.4564 5.30961 12.0771 6.14972C11.6978 6.98983 11.6067 7.93171 11.818 8.82893C10.1551 8.74558 8.52832 8.31345 7.04328 7.56059C5.55823 6.80773 4.24812 5.75098 3.19799 4.45893C2.82628 5.09738 2.63095 5.82315 2.63199 6.56193C2.63199 8.01193 3.36999 9.29293 4.49199 10.0429C3.828 10.022 3.17862 9.84271 2.59799 9.51993V9.57193C2.59819 10.5376 2.93236 11.4735 3.54384 12.221C4.15532 12.9684 5.00647 13.4814 5.95299 13.6729C5.33661 13.84 4.6903 13.8646 4.06299 13.7449C4.32986 14.5762 4.85 15.3031 5.55058 15.824C6.25117 16.345 7.09712 16.6337 7.96999 16.6499C7.10247 17.3313 6.10917 17.8349 5.04687 18.1321C3.98458 18.4293 2.87412 18.5142 1.77899 18.3819C3.69069 19.6114 5.91609 20.2641 8.18899 20.2619C15.882 20.2619 20.089 13.8889 20.089 8.36193C20.089 8.18193 20.084 7.99993 20.076 7.82193C20.8949 7.2301 21.6016 6.49695 22.163 5.65693L22.162 5.65593Z" fill=""></path>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_48_1502">
                                                <rect width="24" height="24" fill="white"></rect>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    </a>
                                    <a rel="noopener noreferrer" href="#" title="LinkedIn" className="flex items-center justify-center w-10 h-10 rounded-full dark:bg-violet-400 dark:text-gray-900">
                                    <svg className="text-gray-300" width="24" height="24" viewBox="0 0 24 24" fill="#d1d8e0" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_48_1505)">
                                        <path d="M6.94 5.00002C6.93974 5.53046 6.72877 6.03906 6.35351 6.41394C5.97825 6.78883 5.46944 6.99929 4.939 6.99902C4.40857 6.99876 3.89997 6.78779 3.52508 6.41253C3.1502 6.03727 2.93974 5.52846 2.94 4.99802C2.94027 4.46759 3.15124 3.95899 3.5265 3.5841C3.90176 3.20922 4.41057 2.99876 4.941 2.99902C5.47144 2.99929 5.98004 3.21026 6.35492 3.58552C6.72981 3.96078 6.94027 4.46959 6.94 5.00002ZM7 8.48002H3V21H7V8.48002ZM13.32 8.48002H9.34V21H13.28V14.43C13.28 10.77 18.05 10.43 18.05 14.43V21H22V13.07C22 6.90002 14.94 7.13002 13.28 10.16L13.32 8.48002Z" fill=""></path>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_48_1505">
                                            <rect width="24" height="24" fill="white"></rect>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    </a>
                                </div>
                            </div>
                            
                        </motion.div>
                    </Container>
                </div>
                <img src={ CurlRight }  className="bottom-0 right-0 absolute object-contain w-full opacity-[0.8] z-[2]"  />

                <div className="bottom-0 left-0 absolute object-cover w-full opacity-[0.7]" aria-label="shape" name="shape">
                
                    <motion.svg
                        animate={controls}
                        initial="hidden"
                        variants={leftImageVariant}
                        width="143" height="138" viewBox="0 0 143 138" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="25" cy="118" r="101" stroke="url(#paint0_linear_52:83)" strokeWidth="34"></circle>
                        <defs>
                            <linearGradient id="paint0_linear_52:83" x1="-12.7969" y1="-37.3359" x2="99.2109" y2="173.773" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#4A6CF7"></stop>
                            <stop offset="1" stopColor="#4A6CF7" stopOpacity="0"></stop>
                            </linearGradient>
                        </defs>
                    </motion.svg>
                </div>
            </footer>
        
  )
}

export default Footer
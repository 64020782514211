import logo from './logo.svg';
import './App.css';
import { Route, Routes } from "react-router-dom";
import { AnimatePresence } from 'framer-motion';
import { Header, MainContainer, Footer, ContactsContainer, AboutContainer, ServicesContainer } from "./components";
function App() {
  return (
    <AnimatePresence mode={"wait"}>
      <div className="w-full  flex flex-col">
        <Header/>
        <main className="w-full">
          <Routes>
              <Route path="/*" element={<MainContainer />} />
              <Route path="/contactus" element={<ContactsContainer />} />
              <Route path="/aboutus" element={<AboutContainer />} />
              <Route path="/services" element={<ServicesContainer />} />
              
          </Routes>
        </main>
        <Footer/>
      </div>
      
    </AnimatePresence>
  );
}

export default App;

import React from 'react'
import Logo from './../logo.svg';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import { VscListSelection } from 'react-icons/vsc';
import Container from "./Container";
import logo from './../assets/logo.png';
const Header = () => {
  return (
    <header  className="fixed top-0 z-40  w-full">
        <motion.nav 
            
            className=" w-full shadow bg-white ">
            <Container>
                <motion.div
                    initial={{ y: 10 }}
                    animate={{ y: 0 }}
                    transition = {{ delay:0.3,duration:"0.5", type:"spring", stiffness:"120" }}
                 className="flex flex-wrap items-center justify-between py-2 gap-6 md:py-4 md:gap-0 relative">
                    <input aria-hidden="true" type="checkbox" name="toggle_nav" id="toggle_nav" className="hidden peer"/>
                    <div className="relative z-20 w-full flex justify-between lg:w-max md:px-0">
                        <a href="/" className="flex items-center">
                            <img src={logo} alt="koga step logo" className="h-12 mr-3 sm:h-12"/>
                            <span className="self-center text-xl font-semibold whitespace-nowrap bg-gradient-to-r from-blue-500 to-teal-600 bg-clip-text text-transparent">Step Support Co.</span>
                        </a>
                        
                        <div className="relative flex items-center lg:hidden max-h-10">
                            <label role="button" htmlFor="toggle_nav" aria-label="humburger" id="hamburger" className="relative  p-6 -mr-6">
                                <div aria-hidden="true" id="line" className="m-auto h-0.5 w-5 rounded bg-sky-900 dark:bg-gray-300 transition duration-300"></div>
                                <div aria-hidden="true" id="line2" className="m-auto mt-2 h-0.5 w-5 rounded bg-sky-900 dark:bg-gray-300 transition duration-300"></div>
                            </label>
                        </div>
                    </div>
                    <div aria-hidden="true" className="fixed z-10 inset-0 h-screen w-screen bg-white/70 backdrop-blur-2xl origin-bottom scale-y-0 transition duration-500 peer-checked:origin-top peer-checked:scale-y-100 lg:hidden dark:bg-gray-900/70"></div>
                    <div className="flex-col z-20  gap-6 px-8 rounded-3xl border border-gray-100 bg-white shadow-2xl shadow-gray-600/10  items-center justify-end w-full invisible opacity-0 translate-y-1  absolute top-full left-0 transition-all duration-300 scale-95 origin-top 
                                lg:relative lg:scale-100 lg:peer-checked:translate-y-0 lg:translate-y-0 lg:flex lg:flex-row lg:items-center lg:gap-0 lg:p-0 lg:bg-transparent lg:w-7/12 lg:visible lg:opacity-100 lg:border-none
                                peer-checked:scale-100 peer-checked:opacity-100 peer-checked:visible lg:shadow-none 
                                dark:shadow-none dark:bg-gray-800 dark:border-gray-700">
                    
                        <div className="text-gray-500 dark:text-gray-300  lg:w-auto w-full lg:pt-0 xs:py-12 md:py-0">
                            <ul className="tracking-wide flex-col flex lg:flex-row gap-6 xs:p-4 lg:gap-0 xs:items-center">
                                <li>
                                    <a href="/" className="font-poppins font-normal cursor-pointer text-[16px] block md:px-4 transition hover:text-primary">
                                        <span>Home</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="/aboutus" className="font-poppins font-normal cursor-pointer text-[16px] block md:px-4 transition hover:text-primary">
                                        <span>About</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="/services" className="font-poppins font-normal cursor-pointer text-[16px] block md:px-4 transition hover:text-primary">
                                        <span>Services</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="/contactus" className="font-poppins font-normal cursor-pointer text-[16px] block md:px-4 transition hover:text-primary">
                                        <span>Contact Us</span>
                                    </a>
                                </li>
                            </ul>
                        </div>

                        
                    </div>
                </motion.div>
            </Container>
        </motion.nav>
    </header>

  )
}

export default Header
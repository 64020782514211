import React from 'react'
import stepping_up from './../assets/stepping_up.svg';
import Container from "./Container";
const AboutContainer = () => {
  return (
    <div className="mt-12 h-full">
      <section className="bg-white dark:bg-gray-900  flex items-center justify-center">
          <Container>
              <div className="grid max-w-screen-xl px-4 pt-20 pb-8 mx-auto lg:gap-8 xl:gap-12 lg:py-16 lg:grid-cols-12 lg:pt-28">
                  <div className="mr-auto place-self-center xs:col-span-6 lg:col-span-7">
                      <h1 className="max-w-2xl mb-4 text-4xl font-extrabold leading-none tracking-tight md:text-5xl xl:text-6xl dark:text-white">We, Step Support Co.</h1>
                      <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400 mt-8">We specialize in connecting top talent with leading organizations across various industries. With years of experience in the staffing and recruitment industry, we have developed a deep understanding of the needs and expectations of both clients and candidates.</p>
                      <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">Our team of experienced recruiters is dedicated to finding the best candidates for our clients, no matter how niche or specialized the position may be. We take the time to understand the unique needs and requirements of each client, and we work tirelessly to identify and attract the most qualified candidates for the job.</p>
                  </div>
                  <div className="xs:col-span-6 lg:mt-0 xs:py-8 lg:col-span-5 lg:flex">
                      <img src={ stepping_up } alt="growth image"/>
                  </div>                
              </div>
          </Container>
      </section>
    </div>
  )
}

export default AboutContainer

import React from 'react'
import contactus from './../assets/contact-us.svg';
import Container from "./Container";
import { BsTelephone } from "react-icons/bs";
import { FiMapPin } from "react-icons/fi";
const ContactsContainer = () => {
  return (
    <div className="mt-12 h-full">
      <section className="bg-white dark:bg-gray-900 flex items-center justify-center">
        <Container>
          <div className="grid max-w-screen-xl px-4 pt-20 pb-8 mx-auto lg:gap-8 xl:gap-12 lg:py-16 lg:grid-cols-12 lg:pt-28">
            <div className="mr-auto place-self-center xs:col-span-6 lg:col-span-7">
              <h1 className="max-w-2xl mb-4 text-4xl font-extrabold leading-none tracking-tight md:text-5xl xl:text-6xl dark:text-white">Contact Us</h1>
              <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">Whether you are a client looking for top talent, or a candidate looking for your next career opportunity, we are here to help. Contact us today to learn more about how we can assist you with all your staffing and recruitment needs.</p>
              
              <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
                <a href="tel:08067784777" className="inline-flex items-center justify-center w-full px-5 py-3 text-sm font-medium text-center text-gray-900 border border-gray-200 rounded-lg sm:w-auto lg:w-[180px] hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
                  <BsTelephone /> <span className="ml-2">Call Us</span>
                </a>
                <a href="https://maps.app.goo.gl/wUtVxHfck1MvW7U79?g_st=iw" target="_blank" className="inline-flex items-center justify-center w-full px-5 py-3 text-sm font-medium text-center text-gray-900 border border-gray-200 rounded-lg sm:w-auto lg:w-[180px] hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
                  <FiMapPin /> <span className="ml-2">Reach Us</span>
                </a>
              </div>
            </div>
            <div className="lg:mt-0 xs:py-8 xs:col-span-6  lg:col-span-5 lg:flex">
              <img src={ contactus } alt="hcontact us" className="h-full lg:h-[500px]" />
            </div>
          </div>
        </Container>
      </section>

      <section className="bg-gray-50 dark:bg-gray-800">
        <div className="max-w-screen-xl px-4 py-8 mx-auto lg:py-16 lg:px-6">
          <div className="max-w-screen-sm mx-auto text-center">
            <h2 className="mb-4 text-3xl font-extrabold leading-tight tracking-tight text-gray-900 dark:text-white">Office Address</h2>
            <p className="mb-6 font-light text-gray-500 dark:text-gray-400 md:text-lg">246-6 2F, KAMISHIBUTARECHO ASHIKAGA TOCHIGI, 326-0335</p>
            <p className="mb-6 font-light text-gray-500 dark:text-gray-400 md:text-lg">8:00 ~ 17:00</p>
            
          </div>
        </div>
      </section>
    </div>
  )
}

export default ContactsContainer
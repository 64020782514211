import React from 'react'
import staffing from './../assets/staffing-image.png';
import interview from './../assets/interview.svg';
import Container from "./Container";
const ServicesContainer = () => {
  return (
    <div className="mt-8 h-full">
      <section className="bg-white dark:bg-gray-900  flex items-center justify-center ">
          <Container>
              <div className="grid max-w-screen-xl px-4 pt-20 pb-8 mx-auto lg:gap-8 xl:gap-12 lg:py-12 lg:grid-cols-12 lg:pt-28">
                  <div className="mr-auto place-self-center xs:col-span-6  lg:col-span-7 ">
                      <h1 className="max-w-2xl mb-4 text-4xl font-extrabold leading-none tracking-tight md:text-5xl xl:text-6xl dark:text-white">Temporary Staffing</h1>
                      <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400 mt-8">We provide staffing solutions for businesses and organizations of all sizes, across a wide range of industries. Whether you need temporary staff to cover peak periods, seasonal demands, or unexpected staff shortages, we can help.</p>
                      <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">Our team of experienced recruiters works closely with you to understand your staffing needs and find the right candidates for your temporary positions. We have a large pool of pre-screened candidates with diverse skills and backgrounds, and we can quickly match them to your job requirements.</p>
                  </div>
                  <div className="lg:mt-0 xs:py-8 xs:col-span-6  lg:col-span-5 lg:flex">
                      <img src={ staffing } alt="hero image" className="grayscale-[50%]"/>
                  </div>                
              </div>
          </Container>
      </section>

      <section className="bg-white dark:bg-gray-900  flex items-center justify-center">
          <Container>
              <div className="grid max-w-screen-xl px-4 pt-20 pb-8 mx-auto lg:gap-8 xl:gap-12 lg:py-12 lg:grid-cols-12 lg:pt-28">
                   
                  <div className="mr-auto place-self-center xs:col-span-6  lg:col-span-7 pr-8">
                      <h1 className="max-w-2xl mb-4 text-4xl font-extrabold leading-none tracking-tight md:text-5xl xl:text-6xl dark:text-white">Recruitment</h1>
                      <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400 mt-8">We are a full-service recruitment agency that specializes in finding and placing top talent for businesses and organizations of all sizes, across a wide range of industries. Our mission is to help our clients build successful teams by providing customized recruitment solutions that meet their unique needs.</p>
                      <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">Our team of experienced recruiters uses a variety of methods to source and identify qualified candidates, including targeted searches, online job postings, and social media outreach. We also use advanced assessment tools and techniques to evaluate candidates' skills, personality traits, and work preferences, ensuring that we find the best fit for each position.</p>
                  </div>
                  <div className="xs:col-span-6 xs:py-8  lg:mt-0 lg:col-span-5 lg:flex">
                      <img src={ interview } alt="hero image"/>
                  </div>               
              </div>
          </Container>
      </section>

      
    </div>
  )
}

export default ServicesContainer
import React from 'react'
import hero from './../assets/hero-image.svg';
import Container from "./Container";
const Hero = () => {
  return (
    <section className="bg-white dark:bg-gray-900 h-full flex items-center justify-start px-2">
        <Container>
            <div className="grid max-w-screen-xl px-4 pt-20 pb-8 mx-auto gap-4 lg:gap-8 xl:gap-12 lg:py-16 lg:grid-cols-12 lg:pt-28 items-start justify-between">
                <div className="mr-auto place-self-center xs:col-span-6 lg:col-span-7 flex-col items-center justify-center">
                    <h1 className="max-w-2xl mb-4 text-4xl font-extrabold leading-none tracking-tight md:text-5xl xl:text-6xl dark:text-white">Intellect Organization<br/> <span className=" bg-gradient-to-r from-blue-500 to-teal-600 bg-clip-text text-transparent">Human Support</span></h1>
                    <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">Step Support Co., Ltd, where we believe in the power of people. We are passionate about connecting organizations with top talent and helping job seekers find their dream careers.</p>
                    <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
                        <a href="/contactus" className="inline-flex items-center justify-center w-full px-5 py-3 text-sm font-medium text-center text-gray-900 border border-gray-200 rounded-lg sm:w-auto lg:w-[180px] hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
                        Contact Us
                        </a> 
                        <a href="/services" className="inline-flex items-center justify-center w-full px-5 py-3 text-sm font-medium text-center text-gray-900 border border-gray-200 rounded-lg sm:w-auto lg:w-[180px] hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
                        Our Services
                        </a> 
                    </div>
                </div>
                <div className=" xs:col-span-6 lg:mt-0 xs:py-8 lg:col-span-5 flex lg:flex lg:h-full items-center justify-center">
                    <img src={ hero } alt="hero image"  className="sm:h-[300px] lg:h-[600px]" />
                </div>                
            </div>
        </Container>
    </section>
  )
}

export default Hero
import React from 'react'
import Hero from "./Hero";
import ServiceIntro from "./ServiceIntro";
import Container from "./Container";
const MainContainer = () => {
  return (
    <div className=" pt-6">
       
            <Hero/>
            <ServiceIntro/>
        
    </div>
  )
}

export default MainContainer